import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WindowCommon } from '../common-classes/window.common.class';
import { ComponentService } from '../services/component.service';

@Injectable({
    providedIn: 'root'
})
export class DirectAccessGuard implements CanActivate {

    windowCommon = new WindowCommon();

    constructor(private router: Router,
        private componentService: ComponentService
        ) { }
    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if(this.router.url === '/') {
            await this.componentService.updateCommon('WindowCommon', 'TokenRefreshNeeded', true);
            await  this.router.navigate(['/login']);
        } else {
            return true;
        }
        return true;
    }
}