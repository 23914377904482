import { Routes } from '@angular/router';
import { DirectAccessGuard } from './guards/direct-access.guard';
import { environment } from '../environments/environment';
import { NothingGuard } from './guards/nothing.guard';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

export const routes: Routes = [

  { path: '', redirectTo: 'home', pathMatch: 'full' }, //default route
  {path: 'home', loadComponent: () => import('./components/home/home.component').then(mod => mod.HomeComponent)},
  {path: 'subscribe', loadComponent: () => import('./components/subscribe/subscribe2.component').then(mod => mod.Subscribe2Component)},
      {path: 'share', loadComponent: () => import('./components/share/share.component').then(mod => mod.ShareComponent)},
      {path: 'login', loadComponent: () => import('./components/login/login.component').then(mod => mod.LoginComponent)},
      {path: 'manage-subscription', loadComponent: () => import('./components/manage-subscription/manage-subscription.component').then(mod => mod.ManageSubscriptionComponent)},
      {path: 'cancelled-subscription', loadComponent: () => import('./components/cancelled-subscription/cancelled-subscription.component').then(mod => mod.CancelledSubscriptionComponent)},
      {path: 'password-reset', loadComponent: () => import('./components/password-reset/password-reset.component').then(mod => mod.PasswordResetComponent)},
      {path: 'install', loadComponent: () => import('./components/install/install.component').then(mod => mod.InstallComponent)},

    {path: 'parent', loadComponent: () => import('./components/parent/parent.component').then(mod => mod.ParentComponent),
    canActivate: [DirectAccessGuard, environment.environmentName === 'local' ? NothingGuard : NothingGuard]
    },
    {path: 'child', loadComponent: () => import('./components/child/child.component').then(mod => mod.ChildComponent),
    canActivate: [DirectAccessGuard, environment.environmentName === 'local' ? NothingGuard : NothingGuard]
    },
//  {path: 'parent',component: ParentComponent, canActivate: [DirectAccessGuard, environment.environmentName === 'local' ? NothingGuard : NothingGuard]},
    // {path: 'child',component: ChildComponent, canActivate: [DirectAccessGuard, environment.environmentName === 'local' ? NothingGuard : NothingGuard]},
    {path: 'subscribe', loadComponent: () => import('./components/subscribe/subscribe.component').then(mod => mod.SubscribeComponent)},
    {path: 'set-password', loadComponent: () => import('./components/set-password/set-password.component').then(mod => mod.SetPasswordComponent)},
      {
        path: '**',
        component: PageNotFoundComponent
      },
    
];
