import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class NothingGuard implements CanActivate {

  

    constructor(private router: Router
        ) { }
    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        return true;
        
    }
}