import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
  })
export class ErrorHandlerService extends ErrorHandler {

    constructor(private loggingService: LoggingService) {
        super();
    }

    override handleError(error: any) {
       
        // exclude errors like error-handler.service.ts:23 ERROR TypeError: Cannot read properties of null (reading 'update')
    // at _AudioService.<anonymous> (audio.service.ts:547:43)
    // caused by navigating away with functions are running

        if (error?.status === 0) {
            // don't log http errors to do with connectivity
        } else {  
            this.loggingService.logException(error); // Manually log exception
        }
        super.handleError(error);
    }


}
