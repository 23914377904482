import { ApplicationConfig, provideZoneChangeDetection, isDevMode, ErrorHandler } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/http-error.interceptor';
import { ErrorHandlerService } from './services/error-handler.service';
import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([AuthInterceptor]),
    ),
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    // {
    //   provide: IMAGE_LOADER,
    //   useValue: (config: ImageLoaderConfig) => {
    //     return `${environment.images}${config.src}${config.width}.webp`;
    //   },
    // },
    // provideServiceWorker('ngsw-worker.js', {
    //         enabled: !isDevMode(),
    //         registrationStrategy: 'registerWhenStable:30000',
    //         // registrationStrategy: 'registerWithDelay:60000',
    //       })
        ]
};
