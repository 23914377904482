import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from '../../services/cognito.service';
import { LoggingService } from '../../services/logging.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  standalone: true
})
export class PageNotFoundComponent {
    
  constructor(
    private loggingService: LoggingService,
    public router: Router,
    private cognitoService: CognitoService,
  ) {  }

 
urlNotFound:string = '';  
async ngOnInit() {
  this.urlNotFound = window.location.href
  const error: Error = {
    stack: 'PageNotFound'
    , name: 'PageNotFound'
    , message: 'PageNotFound ' + this.urlNotFound 
  };
  this.loggingService.logException(error);
  

}


}



