import {inject} from '@angular/core';
import {HttpErrorResponse, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {EMPTY, NEVER, from, lastValueFrom, throwError} from 'rxjs';
import {catchError, delay} from 'rxjs/operators';
import { CognitoService } from '../services/cognito.service';
import { environment } from '../../environments/environment';
import { AppStore } from '../stores/app.store';
import { HttpResponse } from '@microsoft/signalr';



export function AuthInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) {

  return from(handle(req, next));

}

export async function handle(request: HttpRequest<any>, next: HttpHandlerFn) {
  // if your getAuthToken() function declared as "async getAuthToken() {}"
  let authReq = request;
  if (
    request.url.includes('HttpStripeCreateSetupIntent')
    || request.url.includes('HttpStripeSubscribe')
    || request.url.includes('HttpSetChildrenPassword')
    || request.url.includes('HttpCheckSubscriptionExists')
    || request.url.includes('HttpVersion')
    ) {
      let site = '';
      if (environment.environmentName !== 'local') {
        site = '.azurewebsites.net';
      }

      let loadBalancer = '';
      if (environment.environmentName !== 'local') {
        loadBalancer = '1'; //In future '1' can be replaced by random number to load balance
      }

      const urlReq = request.clone({
        url: environment.apiName + loadBalancer + site + request.url
      });
      return lastValueFrom(next(urlReq));
    } else {


  const cognitoService = inject(CognitoService);
  const appStore = inject(AppStore);

      // Token refresh and error handling is in this service
      const token = await cognitoService.getAccessToken();
      // console.log('token', token);
      // console.log('appStore', appStore);
      const cognitoUser = await appStore.CognitoUser.refFirst();

      const jwk = await cognitoService.verifyAccessToken(token);

      if (!jwk) {
        cognitoService.signOut();
      }
      // }
      // jwk = '{"keys":[{"alg":"RS256","e":"AQAB","kid":"iDxZaYUnqoJ5X5q95ZCbhn2E5k+xrakPEm41UL4FqMto=","kty":"RSA","n":"3bqtW_UFs4QwTAuM_5A7rbHfoMvqWmI3Q_SamvsFq7hbk1MpjkrKvBvgw7iTOc09Vwi1eeWNA-2TTK-yptuypFo9BYFJEKAtJOEX7vrMoqLnC83klhjnorRmNBYSibFahsTwtqh88kNth41xtgpmtzB6Qkdr8sYCiXdECeMgfSMNemdgR4VYpJizf1s5F25vi3UmyEOpbEDzLnr8Zpt_JkpGf_fLMJDf70vUemnlWcqLy2gMRXZl3lX4F8eUaOiAjZ5lyHFqWCTk6td5wkZAkYI3bnoJmAtetgAMbZOGkwxs_d-ONsv1TezJOa7eAbs6FgQUySjDo5p-MumPYU5VMMwYlQ","use":"sig"},{"alg":"RS256","e":"AQAB","kid":"+am+O6DWF37zjyBSmpG0wk7QwsmmXCHGL4b/7uVGxUgDs=","kty":"RSA","n":"wAX8NqbVc8dXDMBX3gcQPclpdnS2hb7v2sXnmL-qc8lzdqoMyunLeRaYLczOl5DFCL4RitEVQqZsNBCMGDKUUcjVWQywDBGm_h4UPlYZBMfHiMpqQCcseXDLj7MDs-BJLaZei2ADB1mZdNQGN1yuYiydCwjdiQqMXnjHbyCBGOuuYsbWfIYJOfq4HAPUkC6neZ1u3Gko-PucE_uHaaj09BMj8wCIyrm-Jq2GW_is78nfcog15DrWce1JoYYj9HJwz8L5C7T7x9BHJqWglsFNiah9RhAyR-eXfRY3uzZVcXPquU-giYnt1XWUFtrVgHLseW9lkmUeI99_JV66vPvGgGW9w","use":"sig"}]}';

      let site = '';
      if (environment.environmentName !== 'local') {
        site = '.azurewebsites.net';
      }

      let loadBalancer = '';
      if (environment.environmentName !== 'local') {
        loadBalancer = '1'; //In future '1' can be replaced by random number to load balance
      }

    // This stops the request where the user has been logged out or users have been switched while waiting for the request
    // prevents an error being logged 
    if (!cognitoUser) {
      return await lastValueFrom(NEVER);
    }

     authReq = request.clone({
     url: environment.apiName + loadBalancer + site + request.url, 
        headers: request.headers.set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + token)
            .set('Jwk', jwk)
            .set('Storage', cognitoUser.item.Storage)
            // .set('ngsw-bypass', null)
        });
    }


  return await lastValueFrom(next(authReq).pipe(
    catchError((error: HttpErrorResponse) => {
       if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        // console.error('An error occurred:', error.error);
        return EMPTY;
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        // console.log('error', error);
        // console.error(`Backend returned code ${error.status}, body was: `, error.error);
        if (
          request.url.includes('HttpUpdateEntity')
           && error.status === 409) {
            return next(authReq).pipe(delay(1000));
        } else if (error.status === 401) {
          // console.log('401 error', error);
          // return from(this.handle401Error(authReq, next));

        }
        return throwError(() => error);
      }

    })
));



}